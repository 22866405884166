import { PROMOTION_OBJECT } from '@lib/appconst'
import { IStatus } from '@models/global'
import { UserModel } from '@models/user/IUserModel'
import moment from 'moment-timezone/moment-timezone'

export class PromotionModel {
  id?: number
  uniqueId?: string
  name?: string
  code?: string
  description?: string

  isPublished?: boolean
  status?: IStatus
  isActive?: boolean
  startDate?: Date
  endDate?: Date

  reachMinRequest?: number
  totalRedeemAmount?: number
  maxRedeemTimes?: number

  lastModificationTime?: Date
  lastModifierUser?: UserModel

  promotionForObject?: number

  constructor() {
    this.id = undefined
    this.isActive = true
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new PromotionModel(), obj)
    newObj.startDate = obj.startDate ? moment(obj.startDate) : undefined
    newObj.endDate = obj.endDate ? moment(obj.endDate) : undefined
    newObj.lastModificationTime = obj.lastModificationTime ? moment(obj.lastModificationTime) : undefined
    newObj.promotionForObject =
      obj.maxUsePerAccount === 1
        ? PROMOTION_OBJECT.APPLY_FOR_FIRST_USED
        : obj.promotionSpecificUsers?.length > 0
        ? PROMOTION_OBJECT.APPLY_FOR_SPECIFIC_USER
        : obj.promotionSpecificProducts?.length > 0
        ? PROMOTION_OBJECT.APPLY_FOR_SPECIFIC_PRODUCT_TYPE
        : PROMOTION_OBJECT.NOT_SET
    return newObj
  }

  public static assigns<T>(objs) {
    const results: PromotionModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class PromotionDetailModel {
  id?: number
  uniqueId?: string
  name?: string
  code?: string
  description?: string

  isPublished?: boolean
  isActive?: boolean
  startDate?: Date
  endDate?: Date

  reachMinRequest?: number
  totalRedeemAmount?: number

  lastModificationTime?: Date
  lastModifierUser?: UserModel

  promotionForObject?: number

  constructor() {
    this.id = undefined
    this.isActive = true
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new PromotionDetailModel(), obj)
    newObj.startDate = obj.startDate ? moment(obj.startDate) : undefined
    newObj.endDate = obj.endDate ? moment(obj.endDate) : undefined
    newObj.lastModificationTime = obj.lastModificationTime ? moment(obj.lastModificationTime) : undefined

    newObj.promotionForObject =
      obj.maxUsePerAccount === 1
        ? PROMOTION_OBJECT.APPLY_FOR_FIRST_USED
        : obj.promotionSpecificUsers?.length > 0
        ? PROMOTION_OBJECT.APPLY_FOR_SPECIFIC_USER
        : obj.promotionSpecificProducts?.length > 0
        ? PROMOTION_OBJECT.APPLY_FOR_SPECIFIC_PRODUCT_TYPE
        : PROMOTION_OBJECT.NOT_SET
    return newObj
  }

  public static assigns<T>(objs) {
    const results: PromotionDetailModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
