export default class Stores {
  static AuthenticationStore = 'authenticationStore'
  static RoleStore = 'roleStore'
  static TenantStore = 'tenantStore'
  static UserStore = 'userStore'
  static SessionStore = 'sessionStore'
  static AccountStore = 'accountStore'
  static LanguageStore = 'languageStore'

  // Master data
  static TruckBrandStore = 'truckBrandStore'
  static TruckTypeStore = 'truckTypeStore'
  static ProductTypeStore = 'productTypeStore'
  static RatingBadgeStore = 'ratingBadgeStore'
  static TransportationCostStore = 'transportationCostStore'
  static MasterDataStore = 'masterDataStore'
  static ReasonRejectStore = 'reasonRejectStore'

  // Resident & Staff & Shop owner
  static StaffStore = 'staffStore'
  static PartnerStore = 'partnerStore'
  static CustomerStore = 'customerStore'

  // News & Event
  static NewsStore = 'newsStore'
  static NewsCategoryStore = 'newsCategoryStore'
  static FeedbackStore = 'feedbackStore'
  static AnnouncementStore = 'announcementStore'

  // Finance
  static FinanceStore = 'financeStore'
  static WithdrawStore = 'withdrawStore'
  static CashAdvanceStore = 'cashAdvanceStore'
  static ReceiptStore = 'receiptStore'
  static ExpenseMandateStore = 'expenseMandateStore'
  static FeeTypeStore = 'feeTypeStore'

  // Workflow
  static WfStatusStore = 'wfStatusStore'
  static WfPriorityStore = 'wfPriorityStore'
  static WfCustomFieldStore = 'wfCustomFieldStore'
  static WfRoleStore = 'wfRoleStore'
  static WfTrackerStore = 'wfTrackerStore'
  static WfConfigurationStore = 'wfConfigurationStore'
  static WorkflowStore = 'workflowStore'

  // File
  static FileStore = 'fileStore'

  // Common
  static AuditLogStore = 'auditLogStore'
  static CommentStore = 'commentStore'
  static NotificationTemplateStore = 'notificationTemplateStore'
  static TermConditionStore = 'termConditionStore'

  // Reminder
  static ReminderStore = 'reminderStore'

  // Truck
  static TruckStore = 'truckStore'

  // Removal
  static RemovalRequestStore = 'removalRequestStore'

  // Dismantling
  static DismantlingStore = 'dismantlingStore'

  // Truck Hiring
  static TruckHiringStore = 'truckHiringStore'

  // Holiday
  static HolidayStore = 'holidayStore'

  static ApplicationSettingStore = 'applicationSettingStore'

  static DashboardStore = 'dashboardStore'

  // Growth
  static PromotionStore = 'promotionStore'
  static CampaignStore = 'campaignStore'
}
