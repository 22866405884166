import { appStatusColors } from '@lib/appconst'
import { ReceiptModel } from '@models/finance/receiptModel'
import { IStatus, Status } from '@models/global'
import { UserModel } from '@models/user/IUserModel'
import moment from 'moment-timezone/moment-timezone'

export class CampaignModel {
  id?: number
  uniqueId?: string
  name?: string
  code?: string
  description?: string

  isPublished?: boolean
  status?: IStatus
  isActive?: boolean
  startDate?: Date
  endDate?: Date

  reachMinRequest?: number
  totalRedeemAmount?: number
  maxRedeemTimes?: number

  lastModificationTime?: Date
  lastModifierUser?: UserModel

  constructor() {
    this.id = undefined
    this.isActive = true
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new CampaignModel(), obj)
    newObj.startDate = obj.startDate ? moment(obj.startDate) : undefined
    newObj.endDate = obj.endDate ? moment(obj.endDate) : undefined
    newObj.lastModificationTime = obj.lastModificationTime ? moment(obj.lastModificationTime) : undefined
    newObj.status = obj.isPublished
      ? new Status('CAMPAIGN_PUBLISHED', appStatusColors.success)
      : new Status('CAMPAIGN_NOT_PUBLISHED', appStatusColors.expired)
    return newObj
  }

  public static assigns<T>(objs) {
    const results: CampaignModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class CampaignDetailModel {
  id?: number
  uniqueId?: string
  name?: string
  code?: string
  description?: string

  isPublished?: boolean
  isActive?: boolean
  startDate?: Date
  endDate?: Date

  reachMinRequest?: number
  totalRedeemAmount?: number

  lastModificationTime?: Date
  lastModifierUser?: UserModel

  constructor() {
    this.id = undefined
    this.isActive = true
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new CampaignModel(), obj)
    newObj.startDate = obj.startDate ? moment(obj.startDate) : undefined
    newObj.endDate = obj.endDate ? moment(obj.endDate) : undefined
    newObj.lastModificationTime = obj.lastModificationTime ? moment(obj.lastModificationTime) : undefined
    return newObj
  }

  public static assigns<T>(objs) {
    const results: CampaignModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class AwardRecipientModel {
  id?: number
  campaignId?: number
  campaign?: CampaignModel
  reachMinRequest?: number
  receipt?: ReceiptModel

  totalRedeemAmount?: number
  isActive?: boolean
  userId?: Date
  user?: UserModel

  lastModificationTime?: Date
  lastModifierUser?: UserModel

  constructor() {
    this.id = undefined
    this.isActive = true
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new AwardRecipientModel(), obj)
    newObj.campaign = CampaignModel.assign(obj.campaign)
    newObj.receipt = ReceiptModel.assign(obj.receipt)
    newObj.user = UserModel.assign(obj.user)
    newObj.lastModificationTime = obj.lastModificationTime ? moment(obj.lastModificationTime) : undefined
    return newObj
  }

  public static assigns<T>(objs) {
    const results: AwardRecipientModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
