import type { PagedResultDto } from '../dto/pagedResultDto'
import http from '../httpService'
import { notifyError, notifySuccess, proccessFromToParams } from '@lib/helper'
import { L, LNotification } from '@lib/abpUtility'
import { downloadFile } from '@lib/helperFile'
import { PromotionDetailModel } from '@models/growth/promotionModel'

class PromotionService {
  public async create(body: any) {
    try {
      const res = await http.post('api/services/app/Promotions/Create', body)
      notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
      return res?.data?.result
    } catch (e) {
      console.log(e, 'bac')
    }
  }

  public async filter(filters: any): Promise<PagedResultDto<PromotionDetailModel>> {
    const params = proccessFromToParams(filters)

    const res = await http.get('api/services/app/Promotions/GetAll', { params })
    const result = res.data.result
    result.items = PromotionDetailModel.assigns(result.items || [])

    return result
  }

  public async update(body: any) {
    const res = await http.put('api/services/app/Promotions/Update', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return res.data.result
  }

  public async getById(id): Promise<PromotionDetailModel> {
    if (!id) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }
    const result = await http.get('/api/services/app/Promotions/Get', { params: { id } })
    return PromotionDetailModel.assign(result.data.result)
  }

  public async activateOrDeactivate(body) {
    const response = await http.post('api/services/app/Promotions/Active', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }

  public async getHistory(filters: any): Promise<PagedResultDto<any>> {
    const params = proccessFromToParams(filters)

    const res = await http.get('api/services/app/Promotions/GetAllPromotionHistories', { params })
    const result = res.data.result
    return result
  }

  public async exportPromotionHistoryUsed(filters: any): Promise<any> {
    const params = proccessFromToParams(filters)

    const res = await http.get('api/Export/ExportPromotionHistory', { params, responseType: 'blob' })
    downloadFile(res.data, 'promotion-history-used-export.xlsx')
  }

  public async publishOrRecall(body) {
    const response = await http.post('api/services/app/Promotions/Publish', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }
}

export default new PromotionService()
