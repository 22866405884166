import {
  AppstoreOutlined,
  BarsOutlined,
  CarOutlined,
  CommentOutlined,
  DashboardOutlined,
  DollarOutlined,
  FlagOutlined,
  GoldOutlined,
  GroupOutlined,
  Html5Outlined,
  MehOutlined,
  PauseCircleOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  RiseOutlined,
  SafetyOutlined,
  SettingOutlined,
  StarOutlined,
  TagsOutlined,
  UserOutlined
} from '@ant-design/icons'
import { NotificationOutlined } from '@ant-design/icons'
import { appPermissions } from '@lib/appconst'

import LoadableComponent from '../../Loadable'

export const layouts: any = {
  userLayout: 'userLayout',
  portalLayout: 'appLayout',
  publicLayout: 'publicLayout'
}

export const layoutRouter: any = {
  userLayout: LoadableComponent(() => import('../UserLayout')),
  appLayout: LoadableComponent(() => import('../AppLayout')),
  publicLayout: LoadableComponent(() => import('../PublicLayout'))
}

export const publicLayout: any = {
  publicNews: {
    path: '/public/news/:id',
    title: 'NEWS',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/communication/news/detail/public-news'))
  },
  termAndCondition: {
    path: '/public/terms-and-conditions',
    title: 'TERM_CONDITIONS',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/public/term-condition'))
  },
  activeEmail: {
    path: '/public/active',
    title: 'ACTIVE_EMAIL',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/public/active-email'))
  }
}

export const userLayout: any = {
  accountLogin: {
    path: '/login',
    title: 'LogIn',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/accounts/Login'))
  },
  forgotPassword: {
    path: '/forgot-password',
    title: 'FORGOT_PASSWORD',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/accounts/ForgotPassword'))
  },
  resetPassword: {
    path: '/reset-password',
    title: 'RESET_PASSWORD',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/accounts/ForgotPassword'))
  }
  // resetPasswordEmployee: {
  //   path: '/account/employee/reset-password',
  //   title: 'RESET_PASSWORD',
  //   layout: layouts.userLayout,
  //   component: LoadableComponent(() => import('../../../scenes/accounts/ForgotPassword'))
  // },
  // resetPasswordPartner: {
  //   path: '/account/partner/reset-password',
  //   title: 'RESET_PASSWORD',
  //   layout: layouts.userLayout,
  //   component: LoadableComponent(() => import('../../../scenes/accounts/ForgotPassword'))
  // },

  // resetPasswordCustomer: {
  //   path: '/account/customer/reset-password',
  //   title: 'RESET_PASSWORD',
  //   layout: layouts.userLayout,
  //   component: LoadableComponent(() => import('../../../scenes/accounts/ForgotPassword'))
  // },
}

export const portalLayouts: any = {
  // Portal
  accountLogout: {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'LOGOUT',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../Logout'))
  },
  exception: {
    path: '/exception',
    permission: '',
    title: 'exception',
    name: 'EXCEPTION',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/common/Exception'))
  },
  dashboard: {
    path: '/dashboard',
    name: 'DASHBOARD',
    permission: '',
    title: 'Dashboard',
    layout: layouts.portalLayout,
    icon: PieChartOutlined,
    component: LoadableComponent(() => import('../../../scenes/common/Dashboard'))
  },
  notification: {
    path: '/user-notification',
    name: 'USER_NOTIFICATION',
    permission: '',
    title: 'User Notification',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/common/Notification'))
  },
  termCondition: {
    path: '/term-condition-form',
    name: 'TERM_CONDITION_SETTING',
    permission: appPermissions.termCondition.page,
    title: 'TEM_CONDITION_SETTING',
    layout: layouts.portalLayout,
    icon: RadarChartOutlined,
    component: LoadableComponent(() => import('../../../scenes/administrator/TermConditionForm'))
  },
  // Master Data
  holiday: {
    path: '/master-data/holiday-management',
    permission: appPermissions.holiday.page,
    title: 'HOLIDAY_MANAGEMENT',
    name: 'HOLIDAY_MANAGEMENT',
    layout: layouts.portalLayout,
    icon: SafetyOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/holiday'))
  },

  truckBrand: {
    path: '/master-data/truck-brand',
    permission: appPermissions.truckBrand.page,
    title: 'TRUCK_BRAND',
    name: 'TRUCK_BRAND',
    layout: layouts.portalLayout,
    icon: SafetyOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/truckBrand'))
  },
  truckType: {
    path: '/master-data/truck-type',
    permission: appPermissions.truckType.page,
    title: 'TRUCK_TYPE',
    name: 'TRUCK_TYPE',
    layout: layouts.portalLayout,
    icon: FlagOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/truckType'))
  },
  productType: {
    path: '/master-data/product-type',
    permission: appPermissions.productType.page,
    title: 'PRODUCT_TYPE',
    name: 'PRODUCT_TYPE',
    layout: layouts.portalLayout,
    icon: GoldOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/productType'))
  },
  ratingBadge: {
    path: '/master-data/rating-badge',
    permission: appPermissions.ratingBadge.page,
    title: 'BADGE',
    name: 'BADGE',
    layout: layouts.portalLayout,
    icon: StarOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/ratingBadge'))
  },
  reasonReject: {
    path: '/master-data/reason-reject',
    permission: appPermissions.reasonReject.page,
    title: 'REASON_REJECT',
    name: 'REASON_REJECT',
    layout: layouts.portalLayout,
    icon: MehOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/reasonReject'))
  },
  requestWaitingReason: {
    path: '/master-data/request-waiting-reason',
    permission: appPermissions.reasonWaiting.page,
    title: 'REQUEST_WAITING_REASON',
    name: 'REQUEST_WAITING_REASON',
    layout: layouts.portalLayout,
    icon: PauseCircleOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/requestWaitingReason'))
  },
  transportationCost: {
    path: '/master-data/transportation-cost',
    permission: appPermissions.transportationCost.page,
    title: 'TRANSPORTATION_COST',
    name: 'TRANSPORTATION_COST',
    layout: layouts.portalLayout,
    icon: GroupOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/transportationCost'))
  },
  promotionCode: {
    path: '/master-data/promotion-code',
    permission: appPermissions.promotion.page,
    title: 'PROMOTION_CODE',
    name: 'PROMOTION_CODE',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/growth/promotion'))
  },
  promotionCodeHistory: {
    path: '/master-data/promotion-code-history/:id',
    permission: appPermissions.promotion.detail,
    title: 'PROMOTION_CODE_HISTORY',
    name: 'PROMOTION_CODE_HISTORY',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/growth/promotion/PromotionCodeHistory'))
  },
  campaigns: {
    path: '/campaigns',
    permission: appPermissions.campaign.page,
    title: 'CAMPAIGN_PARTNER',
    name: 'CAMPAIGN_PARTNER',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/growth/campaign'))
  },
  campaignDetail: {
    path: '/campaign/:id',
    permission: appPermissions.campaign.detail,
    title: 'PROMOTION_CODE',
    name: 'PROMOTION_CODE',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/growth/campaign/CampaignDetail'))
  },
  // Staff
  staffManagement: {
    path: '/staffs',
    name: 'STAFF_MANAGEMENT',
    permission: appPermissions.staff.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/staff'))
  },
  staffCreate: {
    path: '/staff-create',
    name: 'STAFF_MANAGEMENT_CREATE',
    permission: appPermissions.staff.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/staff/StaffDetail'))
  },
  staffDetail: {
    path: '/staff-detail/:id',
    name: 'STAFF_MANAGEMENT_DETAIL',
    permission: appPermissions.staff.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/staff/StaffDetail'))
  },
  // Partner
  partnerManagement: {
    path: '/partners',
    name: 'PARTNER_MANAGEMENT',
    permission: appPermissions.partner.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/partner'))
  },
  partnerCreate: {
    path: '/partner-create',
    name: 'PARTNER_MANAGEMENT_CREATE',
    permission: appPermissions.partner.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/partner/component/partnerDetail'))
  },
  partnerDetail: {
    path: '/partner-detail/:id',
    name: 'PARTNER_MANAGEMENT_DETAIL',
    permission: appPermissions.partner.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/partner/component/partnerDetail'))
  },
  partnerAnalytic: {
    path: '/partner-analytic',
    name: 'PARTNER_ANALYTIC_MANAGEMENT',
    permission: appPermissions.partnerAnalytic.page,
    layout: layouts.portalLayout,
    icon: DashboardOutlined,
    component: LoadableComponent(() => import('../../../scenes/member/partner-analytic'))
  },
  // Customer
  customerManagement: {
    path: '/customers',
    name: 'CUSTOMER_MANAGEMENT',
    permission: appPermissions.customer.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/customer'))
  },
  customerCreate: {
    path: '/customer-create',
    name: 'CUSTOMER_MANAGEMENT_CREATE',
    permission: appPermissions.customer.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/customer/component/customerDetails'))
  },
  customerDetail: {
    path: '/customer-detail/:id',
    name: 'CUSTOMER_MANAGEMENT_DETAIL',
    permission: appPermissions.customer.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/customer/component/customerDetails'))
  },
  // Truck
  truckManagement: {
    path: '/truck-management',
    name: 'TRUCK_MANAGEMENT',
    permission: appPermissions.truckManagement.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/truck'))
  },
  truckCreate: {
    path: '/truck-create',
    name: 'TRUCK_MANAGEMENT_CREATE',
    permission: appPermissions.truckManagement.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/truck/component/truckDetail'))
  },
  truckDetail: {
    path: '/truck-detail/:id',
    name: 'TRUCK_MANAGEMENT_DETAIL',
    permission: appPermissions.truckManagement.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/truck/component/truckDetail'))
  },
  // Communication feedback
  communicationFeedback: {
    path: '/feedback',
    name: 'FEEDBACK',
    permission: appPermissions.feedback.page,
    routedata: { myWorkOrder: false },
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/communication/feedback'))
  },
  communicationFeedbackCreate: {
    path: '/feedback-create',
    name: 'FEEDBACK_DETAIL',
    permission: appPermissions.feedback.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/communication/feedback/components/FeedbackDetail'))
  },
  communicationFeedbackDetail: {
    path: '/feedback-detail/:id',
    name: 'FEEDBACK_DETAIL',
    permission: appPermissions.feedback.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/communication/feedback/components/FeedbackDetail'))
  },
  communicationFeedbackConfig: {
    path: '/feedback-config',
    name: 'FEEDBACK_CONFIG',
    permission: appPermissions.adminMasterData,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/communication/feedback/components/FeedbackConfig'))
  },
  // Announcement
  announcement: {
    path: '/announcement',
    name: 'ANNOUNCEMENT',
    permission: appPermissions.announcement.page,
    layout: layouts.portalLayout,
    icon: NotificationOutlined,
    component: LoadableComponent(() => import('../../../scenes/communication/announcement'))
  },

  // JOB REQUETS
  removalRequest: {
    path: '/removal-request',
    name: 'REMOVAL_REQUEST',
    permission: appPermissions.removal.page,
    layout: layouts.portalLayout,
    icon: CarOutlined,
    component: LoadableComponent(() => import('../../../scenes/jobRequest/removalRequest'))
  },
  removalDetail: {
    path: '/removal-detail/:id',
    name: 'REMOVAL_DETAIL',
    permission: appPermissions.removal.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/jobRequest/removalRequest/components/removalDetail'))
  },
  removalCreate: {
    path: '/removal-create',
    name: 'REMOVAL_CREATE',
    permission: appPermissions.removal.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/jobRequest/removalRequest/components/removalCreate'))
  },
  // Finance
  feeCreateReceiptV1: {
    path: '/receipt-create',
    name: 'FINANCE_RECEIPT',
    permission: appPermissions.receipt.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/finance/receipt/components/CreatingReceipt'))
  },
  receipt: {
    path: '/receipt',
    name: 'FINANCE_RECEIPT',
    permission: appPermissions.receipt.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/finance/receipt'))
  },
  expenseMandate: {
    path: '/expense-mandate',
    name: 'EXPENSE_MANDATE',
    permission: appPermissions.expenseMandate.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/finance/expense-mandate'))
  },
  feeTypes: {
    path: '/fee-types',
    name: 'FEE_TYPE',
    permission: appPermissions.feeType.page,
    layout: layouts.portalLayout,
    icon: BarsOutlined,
    component: LoadableComponent(() => import('../../../scenes/finance/fee-type'))
  },
  withdrawRequest: {
    path: '/withdraw-request',
    name: 'WITHDRAW_REQUEST',
    permission: appPermissions.withdraw.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/finance/withdraw'))
  },
  cashAdvance: {
    path: '/cash-advance',
    name: 'FINANCE_CASH_ADVANCE',
    permission: appPermissions.cashAdvance.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/finance/cash-advance'))
  },
  cashAdvanceDetail: {
    path: '/cash-advance-detail/:userId',
    name: 'FINANCE_CASH_ADVANCE_DETAIL',
    permission: appPermissions.cashAdvance.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/finance/cash-advance/cashAdvanceDetail'))
  },
  paymentSetting: {
    path: '/payment',
    name: 'FINANCE_PAYMENT_SETTING',
    permission: appPermissions.system.paymentSetting,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/finance/payment-setting'))
  },
  // Notification Template
  notificationTemplate: {
    path: '/notification-template',
    name: 'TEMPLATE_NOTIFICATION',
    permission: appPermissions.notificationTemplate.page,
    layout: layouts.portalLayout,
    icon: Html5Outlined,
    component: LoadableComponent(() => import('../../../scenes/notificationTemplate'))
  },
  notificationTemplateCreate: {
    path: '/notification-template-create',
    name: 'TEMPLATE_NOTIFICATION_CREATE',
    permission: appPermissions.notificationTemplate.create,
    layout: layouts.portalLayout,
    icon: Html5Outlined,
    component: LoadableComponent(() => import('../../../scenes/notificationTemplate/components/TemplateDetail'))
  },
  notificationTemplateDetail: {
    path: '/notification-template-detail/:id',
    name: 'TEMPLATE_NOTIFICATION_DETAIL',
    permission: appPermissions.notificationTemplate.detail,
    layout: layouts.portalLayout,
    icon: Html5Outlined,
    component: LoadableComponent(() => import('../../../scenes/notificationTemplate/components/TemplateDetail'))
  },

  //
  applicationSetting: {
    path: '/application-setting',
    name: 'APPLICATION_SETTING',
    permission: appPermissions.applicationSetting.page,
    layout: layouts.portalLayout,
    icon: SettingOutlined,
    component: LoadableComponent(() => import('../../../scenes/applicationSetting'))
  },
  // Workflow
  settingWorkflowConfiguration: {
    path: '/settings/workflow/configuration',
    permission: appPermissions.workflow.page,
    name: 'SETTING_WORKFLOW_CONFIGURATION',
    layout: layouts.portalLayout,
    icon: SettingOutlined,
    component: LoadableComponent(() => import('../../../scenes/workflow/configuration'))
  },
  settingWorkflowStatus: {
    path: '/settings/workflow/status',
    permission: appPermissions.workflow.page,
    name: 'SETTING_WORKFLOW_STATUS',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/workflow/status'))
  },
  settingWorkflowPriority: {
    path: '/settings/workflow/priority',
    permission: appPermissions.workflow.page,
    name: 'SETTING_WORKFLOW_PRIORITY',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/workflow/priority'))
  },
  settingWorkflowRole: {
    path: '/settings/workflow/role',
    permission: appPermissions.workflow.page,
    name: 'SETTING_WORKFLOW_ROLE',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/workflow/role'))
  },
  settingWorkflowTracker: {
    path: '/settings/workflow/tracker',
    permission: appPermissions.workflow.page,
    name: 'SETTING_WORKFLOW_TRACKER',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/workflow/tracker'))
  },
  settingWorkflowCustomField: {
    path: '/settings/workflow/custom-field',
    permission: appPermissions.workflow.page,
    name: 'SETTING_WORKFLOW_CUSTOM_FIELD',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/workflow/customField'))
  },
  settingWorkflowSLA: {
    path: '/settings/workflow/sla',
    permission: appPermissions.workflow.page,
    name: 'SETTING_WORKFLOW_SLA',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/workflow/sla'))
  },
  // Admin
  adminUser: {
    path: '/users',
    permission: appPermissions.adminUser.page,
    title: 'Users',
    name: 'ADMINISTRATION_USER',
    layout: layouts.portalLayout,
    icon: UserOutlined,
    component: LoadableComponent(() => import('../../../scenes/administrator/Users'))
  },
  adminRole: {
    path: '/roles',
    permission: appPermissions.adminRole.page,
    title: 'Roles',
    name: 'ADMINISTRATION_ROLE',
    layout: layouts.portalLayout,
    icon: TagsOutlined,
    component: LoadableComponent(() => import('../../../scenes/administrator/Roles'))
  },
  adminTenants: {
    path: '/tenants',
    permission: appPermissions.adminTenant.page,
    title: 'Tenants',
    name: 'ADMINISTRATION_TENANT',
    layout: layouts.portalLayout,
    icon: AppstoreOutlined,
    component: LoadableComponent(() => import('../../../scenes/administrator/Tenants'))
  },
  adminLanguages: {
    path: '/language',
    permission: appPermissions.adminLanguage.page,
    title: 'Languages',
    name: 'ADMINISTRATION_LANGUAGE',
    layout: layouts.portalLayout,
    icon: AppstoreOutlined,
    component: LoadableComponent(() => import('../../../scenes/administrator/Languages'))
  },
  adminLanguageTexts: {
    path: '/language-text/:id',
    permission: appPermissions.adminLanguage.changeText,
    title: 'ADMINISTRATION_LANGUAGE_TEXT',
    name: 'ADMINISTRATION_LANGUAGE_TEXT',
    layout: layouts.portalLayout,
    icon: AppstoreOutlined,
    component: LoadableComponent(() => import('../../../scenes/administrator/Languages/components/languageTexts'))
  },
  firebaseTest: {
    path: '/firebase-test',
    permission: appPermissions.adminLanguage.changeText,
    title: 'FIREBASE_TEST',
    name: 'FIREBASE_TEST',
    layout: layouts.portalLayout,
    icon: AppstoreOutlined,
    component: LoadableComponent(() => import('../../../scenes/master-data/firebase'))
  }
}

export const routers: any = {
  ...userLayout,
  ...portalLayouts
}

export const appMenuGroups: any = [
  {
    name: 'DASHBOARD_GROUP',
    isGroup: true,
    children: [routers.dashboard, routers.partnerAnalytic]
  },
  {
    name: 'FUNCTION_GROUP',
    isGroup: true,
    children: [
      {
        name: 'ACCOUNT_MANAGEMENT_GROUP',
        icon: UserOutlined,
        permission: '',
        children: [
          routers.staffManagement,
          routers.partnerManagement,
          routers.customerManagement,
          routers.truckManagement
        ]
      },
      routers.removalRequest,
      {
        name: 'FINANCE_GROUP',
        icon: DollarOutlined,
        permission: '',
        children: [
          routers.withdrawRequest,
          routers.expenseMandate,
          routers.receipt,
          routers.cashAdvance
          // routers.paymentSetting
        ]
      },
      routers.announcement,
      {
        name: 'FEEDBACK_GROUP',
        permission: appPermissions.feedback.page,
        icon: CommentOutlined,
        children: [routers.communicationFeedback, routers.communicationFeedbackConfig]
      },
      {
        name: 'GROWTH_GROUP',
        icon: RiseOutlined,
        permission: '',
        children: [routers.promotionCode, routers.campaigns]
      }
    ]
  },
  {
    name: 'SETTING_GROUP',
    isGroup: true,
    children: [
      routers.applicationSetting,
      routers.notificationTemplate,
      routers.termCondition,
      routers.truckBrand,
      routers.truckType,
      routers.productType,
      routers.ratingBadge,
      routers.reasonReject,
      routers.requestWaitingReason,
      routers.transportationCost,
      routers.feeTypes
    ]
  },
  {
    name: 'ADMINISTRATION_GROUP',
    isGroup: true,
    children: [routers.adminRole, routers.adminLanguages]
  }
]
